.subscription {
  width: 80%;
  margin: 0 auto;
  padding: 17px 0;
  font-family: Montserrat, sans-serif;
}

.links {
  align-items: center;
  justify-content: space-between;

  display: flex;

  margin-bottom: 50px;
}

.paySubscriptionButton {
  background: #494580;
  border-radius: 4px;
  height: 47px;

  font-size: 16px;
  line-height: 20px;
  color: white;
}

.cancelSubscriptionButton {
  background: #F7E096;
  border-radius: 4px;
  height: 47px;

  font-size: 16px;
  line-height: 20px;
}

.link.link {
  color: #5B55AF;

  &:hover {
    color: #5B55AF;
  }
}

.badge {
  background: #49C78D;
  color: white;
  height: 64px;
  display: flex;
  align-items: center;
  font-family: Arial;
  font-weight: 400;
  font-size: 16px;
  line-height: 18.4px;
  letter-spacing: 0%;
  padding: 24px;
}

