.notification {
  box-shadow: 0px 7px 15px 0px #00000040;
  height: 48px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.notificationLink.notificationLink {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: 0%;
  color: #5B55AF;
}